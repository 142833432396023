/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 19:37:36
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-27 19:51:27
 */
import echarts from 'echarts'

export default {
  name: 'RoundPieChart',
  data () {
    return {
      baseOption: {
        backgroundColor: '#fff',
        angleAxis: {
          max: 100, // 满分
          clockwise: true, // 逆时针
          // 隐藏刻度线
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        radiusAxis: {
          type: 'category',
          // 隐藏刻度线
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        polar: {
          center: ['50%', '50%'],
          radius: '100%' // 图形大小
        }
      }
    }
  },
  props: {
    width: {
      type: Number,
      default: 200
    },
    height: {
      type: Number,
      default: 200
    },
    barWidth: {
      type: Number,
      default: 30
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    data () {
      this.eChartPie()
    }
  },
  mounted () {
    setTimeout(() => {
      this.eChartPie()
    }, 1000)
  },
  methods: {

    /**
     * 生成线性渐变色
     * @param { string } color0 正常色值
     * @param { string } color1 正常色值
     */
    linearGradientColor (color0, color1) {
      return new echarts.graphic.LinearGradient(1, 0, 0, 0, [{
        offset: 0,
        color: color0
      }, {
        offset: 1,
        color: color1
      }])
    },

    /**
     * 单条数据配置
     * @param { number } value 0-100
     * @param { string } color 正常色值，起点颜色
     * @param { string } endColor 正常色值，终点颜色
     */
    seriesItemOption ({ value, color, endColor, zIndex: z = 1 }) {
      const self = this
      return {
        type: 'bar',
        data: [{
          value,
          itemStyle: {
            color: endColor ? self.linearGradientColor(color, endColor) : color
          }
        }],
        stack: 'a',
        coordinateSystem: 'polar',
        roundCap: true,
        barWidth: self.barWidth,
        z
      }
    },

    /**
     * 数据配置
     */
    seriesOption (data) {
      data.zIndex = 2
      const bgData = {
        color: 'rgba(44, 104, 255, 0.08)',
        value: 100
      }
      return [data, bgData].map(config => this.seriesItemOption(config))
    },

    /**
     * 饼图配置参数
     */
    echartsOption (data) {
      const self = this
      self.baseOption.polar.radius = Math.min(self.width, self.height) - self.barWidth
      return Object.assign({}, self.baseOption, {
        series: self.seriesOption(data)
      })
    },

    /**
     * 绘制饼图
     */
    eChartPie () {
      const echartsOption = this.echartsOption(this.data)
      const chartInstance = echarts.init(this.$refs.chart)

      chartInstance.setOption(echartsOption)
    }
  }
}
